





























































































































import Vue from "vue";
import axios from "axios";
import store from "/front/src/store/WebUI.ts";

import VehicleInformations from "@/components/WebUI/VehicleInformations/index.vue";
import ObjectToCsv from "@/components/OutPut/ObjectToCsv.vue"
import ObjectToBacklog from "@/components/OutPut/ObjectToBacklog.vue"
import ObjectToExcel from "@/components/OutPut/ObjectToExcel.vue"

interface Data {
  parkings:any[];
  parking:number;
  result:any[];
  headers:any;
  successCarNumber:string;
  show:any[];
  dialog:boolean;
  valid:boolean;
  start:string;
  end:string;
  flg:boolean;
}
export default Vue.extend({
  data(): Data {
    return {
      parkings:[],
      parking:1,
      result:[],
      headers:[
        { text: 'INOUT_NO', value: "INOUT_NO" },
        { text: 'PARKING_ID', value: "PARKING_ID" },
        { text: 'ナンバー', value: "NUMBER" },
        { text: '状態', value: "VEHICLE_STATUS_ID" },
        { text: '入庫', value: "ENTRANT_TIME" },
        { text: '出庫', value: "EXIT_TIME" },
      ],
      successCarNumber:"",
      show:[],
      dialog:false,
      valid:false,
      start:"",
      end:"",
      flg:false
    }
  },
  components:{
    VehicleInformations,
    ObjectToCsv,
    ObjectToBacklog,
    ObjectToExcel
  },
  watch: {
    selectServer(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.result = [];
        this.getParkingMgr();
      }
    }
  },
  mounted() {
    if (this.selectServer) { this.getParkingMgr(); }
  },
  methods: {

    async getParkingMgr(): Promise<void> {
      if (!this.selectServer) { alert("物件を選択してください"); this.result = []; return; }
      this.$vloading.show();
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/web_ui/parking_mgr`;
      return await axios
      .get(url, { params: { SERVER_ID: this.selectServer } } )
      .then((res: any) => {
        console.log(res.data);
        this.parkings = res.data.map((parking: any) => {
          if (parking.error) {
            return {
              text: `${parking.PARKING_ID}: ${parking.message}`,
              value: 999
            };
          } else {
            return {
              text: `${parking.PARKING_ID}: ${parking.PARKING_NAME_1} ${parking.PARKING_NAME_2} ${parking.PARKING_NAME_3}`,
              value: parking.PARKING_ID
            };
          }
        })
      })
      .catch((error: any) => {
        console.log(error)
        alert("物件への接続に失敗しました");
        this.result = [];
      })
      .finally(() => {
        this.$vloading.hide();
      });
    },
    // 請求を取り消す
    async update(): Promise<void> {
      alert("実装中です");
      return;
      // if (window.confirm('請求を取り消しますか？')) {
      //   this.$vloading.show();
      //   // 選択したinout_noを配列に収める
      //   const inOutNoAry = []
      //   for (const v in this.records) {
      //     inOutNoAry.push(this.records[v]['INOUT_NO'])
      //   }
      //   const url = `${process.env.VUE_APP_API_URL_BASE}/api/vehicles_information_delete_payment`;
      //   const formData = {
      //     params: {
      //       server: this.selectParkings,
      //       inoutNo: inOutNoAry,
      //       kindId: this.kindId,
      //       reason: "【コモンズ時間帯削除】" + JSON.parse(localStorage.user).name
      //     }
      //   };
      //   return await axios
      //   .post(url, formData.params)
      //   .then(res => {
      //     if (res.data.status != 500) {
      //       alert("成功しました");
      //       document.location.reload();
      //     } else {
      //       alert("更新に失敗しました");
      //     }
      //     this.$vloading.hide();
      //   })
      //   .catch(error => {
      //     console.log(error)
      //     alert("更新に失敗しました");
      //   })
      //   .finally(() => {
      //     this.$vloading.hide();
      //   });
      // }
    },
    async history(place:string, classNumber:string, kana:string, carNumber:string): Promise<void> {
      if (!this.selectServer) { alert("物件を選択してください"); return; }
      this.$vloading.show();
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/vehicles_information_show`;
      const formData = { params: {
        server:      this.selectServer,
        place:       place,
        classNumber: classNumber,
        kana:        kana,
        carNumber:   carNumber
      } };
      return await axios
      .get(url, formData)
      .then(res => {
        this.successCarNumber = place + ' ' + classNumber + ' ' + kana + ' ' + carNumber;
        this.show = res.data;
        this.dialog = true;
      })
      .catch(error => {
        alert(error);
      })
      .finally(() => {
        this.$vloading.hide()
      });
    },
    async getResult(): Promise<void> {
      if (!this.$refs.form.validate()) return
      if (!this.selectServer) { alert("物件を選択してください"); return; }
      this.$vloading.show();
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/web_ui/time_range_deletion`;
      return await axios
      .get(url, { params: { 
        SERVER_ID: this.selectServer,
        PARKING_ID: this.parking,
        START: this.start,
        END: this.end,
        WHERE_FLG: this.flg,
      } } )
      .then((res:any) => {
        this.result = res.data;
      })
      .catch((error: any) => {
        alert(error);
        this.result = [];
      })
      .finally(() => {
        this.$vloading.hide();
      });
    },
  },
  computed: {
    selectServer() {
      return store.state.WebUI.server;
    }
  }
});
