









import Vue from "vue";
import { formatDate } from '@/filters/filters'; // formatDateフィルターのインポート

export default Vue.extend({
  props: {
    selectObj: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      csvData: '',
    };
  },
  watch: {
    selectObj: {
      immediate: true,
      handler(newVal) {
        this.csvData = this.convertToCSV(newVal);
      },
    },
  },
  methods: {
    convertToCSV(objArray: Array<Record<string, any>>): string {
      if (objArray.length === 0) return '';
      
      // ヘッダーの取得
      const headers = Object.keys(objArray[0]);
      
      // 値のエスケープ処理
      const escapeValue = (value: any) => {
        let stringValue = String(value).replace(/"/g, '""'); // " をエスケープ
        
        // ISO 8601形式の日付が文字列として渡されていた場合
        if (value && typeof value === 'string' && value.includes('T') && value.includes(':')) {
          stringValue = formatDate(value); // formatDateを使用して日付を変換
        }
        
        // カンマや改行を含む場合に " で囲む
        if (stringValue.includes(',') || stringValue.includes('\n')) {
          return `"${stringValue}"`; // 値を " で囲む
        }
        return stringValue;
      };
      
      // データ行の取得
      const rows = objArray.map(obj => headers.map(header => escapeValue(obj[header])));
      
      // CSVの組み立て
      const csvContent = [
        headers.join(','), // ヘッダー行
        ...rows.map(row => row.join(',')) // データ行
      ].join('\n');
      
      return csvContent;
    },
    downloadCSV() {
      const blob = new Blob([this.csvData], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'data.csv';
      link.click();
      URL.revokeObjectURL(link.href);
    },
  },
});
