



















































































































import Vue from "vue";
import axios from "axios";
import store from "/front/src/store/WebUI.ts";
interface Data {
  object:any[] | null;
  kindItem:any[];
  valid: boolean;
  name: string;
}
export default Vue.extend({
  data(): Data {
    return {
      object: null,
      kindItem:[
        {text:"字光式ナンバー", value:1},
        {text:"枠・装飾・取付不正など", value:2},
        {text:"その他", value:9}
      ],
      valid: true,
      name: JSON.parse(localStorage.user).name,
    }
  },
  props:{
    updateLoading:{},
    readonlyFlg:{},
    updateObject:{},
    labels:{}
  },
  watch: {
    updateObject: {
      deep: true,
      immediate: true,
      handler(newVal) {
        this.object = { ...newVal };
      }
    }
  },
  methods: {
    async update(
      id:number,
      labelId:number,
      kindId:number,
      note:string,
      name:string
    ): Promise<void> {
      if (!this.$refs.form.validate()) return
      this.$emit('loading', true);
      if (!window.confirm('更新しますか？')) return;
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/vehicle_notes/${id}`;
      const formData = {
        NOTE_KIND_ID: kindId,
        NOTE: note,
        LAST_UPDATER_LABEL_ID: labelId,
        LAST_UPDATER_USER_ID: 2, // commons Userとしての固定値
        LAST_UPDATER_NAME: name,
        LAST_UPDATE_DATE: new Date()
      }
      return await axios
      .patch(url, formData)
      .then((res:any) => {
        if (res.data.error){
          alert(res.data.message);
          return;
        }; 
        this.place = "";
        this.classNumber = "";
        this.kana = "";
        this.carNumber = "";
        this.kindId = 1;
        this.note = "";
        this.$emit('reload');
        this.$emit('close');
        alert("更新しました");
      })
      .catch((error: any) => {
        alert(error);
      })
      .finally(() => {
        this.$emit('loading', false);
      });
    },
  },
  computed: {
    selectServer() {
      return store.state.WebUI.server;
    }
  }
});
