






































































































































import Vue from "vue";
import axios from "axios";
import store from "/front/src/store/WebUI.ts";
import create from "@/components/WebUI/CommonsVehicleNotes/create.vue";
import update from "@/components/WebUI/CommonsVehicleNotes/update.vue";
import VehicleInformations from "@/components/WebUI/VehicleInformations/index.vue";
import ObjectToCsv from "@/components/OutPut/ObjectToCsv.vue"
import ObjectToBacklog from "@/components/OutPut/ObjectToBacklog.vue"
import ObjectToExcel from "@/components/OutPut/ObjectToExcel.vue"

interface Data {
  result:any[];
  headers:any[];
  selected:any[];
  search:string;
  createDialog:boolean;
  createLoading:boolean;
  updateDialog:boolean;
  updateLoading:boolean;
  updateObject:object;
  labels:any[],
  successCarNumber:string;
  show:any[];
  dialog:boolean;
}
export default Vue.extend({
  data(): Data {
    return {
      result:[],
      headers: [
        { text: 'id', value: 'ID' },
        { text: 'ナンバー', value: 'NUMBER' },
        { text: '種別', value: 'NOTE_KIND_ID' },
        { text: 'メモ', value: 'NOTE' },
        { text: '更新者', value: 'LAST_UPDATER_NAME' },
        { text: '登録サーバー', value: 'label' },
        { text: '', value: 'UPDATE' },
        { text: '', value: 'DESTROY' },
        // { text: '更新時間', value: 'LAST_UPDATE_DATE' }
      ],
      selected:[],
      search:"",
      createDialog:false,
      createLoading:false,
      updateDialog:false,
      updateLoading:false,
      updateObject:{},
      labels:[],
      successCarNumber:"",
      show:[],
      dialog:false
    }
  },
  components:{
    VehicleInformations,
    ObjectToCsv,
    ObjectToBacklog,
    ObjectToExcel,
    create,
    update
  },
  mounted() {
    this.getResult();
    this.getLabels();
  },
  methods: {
    updateDialogOpem(item:any) {
      this.updateObject = item;
      this.updateDialog = true;
    },
    async destroy(id:number): Promise<void> {
      if (!window.confirm('本当に削除しますか？')) return;
      this.$vloading.show();
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/vehicle_notes/${id}`;
      return await axios
      .delete(url)
      .then(res => {
        if (res.data.error){ alert(res.data.message); return;};
        alert("成功しました");
        this.getResult();
      })
      .catch(error => {
        alert(error);
      })
      .finally(() => {
        this.$vloading.hide()
      });
    },
    async getResult(): Promise<void> {
      this.$vloading.show();
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/vehicle_notes`;
      return await axios
      .get(url)
      .then((res:any) => {
        console.log(res.data)
        this.result = res.data;
      })
      .catch((error: any) => {
        console.log(error)
      })
      .finally(() => {
        this.$vloading.hide();
      });
    },
    // labelsを取得
    async getLabels(): Promise<void> {
      this.labels = [];
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/labels`;
      return await axios
      .get(url)
      .then(res => {
        console.log(res);
        this.labels = res.data;
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        this.$vloading.hide();
      });
    },
    async history(place:string, classNumber:string, kana:string, carNumber:string): Promise<void> {
      if (!this.selectServer) { alert("物件を選択してください"); return; }
      this.$vloading.show();
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/vehicles_information_show`;
      const formData = { params: {
        server:      this.selectServer,
        place:       place,
        classNumber: classNumber,
        kana:        kana,
        carNumber:   carNumber
      } };
      return await axios
      .get(url, formData)
      .then(res => {
        this.successCarNumber = place + ' ' + classNumber + ' ' + kana + ' ' + carNumber;
        this.show = res.data;
        this.dialog = true;
      })
      .catch(error => {
        alert(error);
      })
      .finally(() => {
        this.$vloading.hide()
      });
    },
  },
  computed: {
    selectServer() {
      return store.state.WebUI.server;
    }
  }
});
