import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import "@mdi/font/css/materialdesignicons.css";
import { LoadingPlugin } from "vuetify-loading-overlay";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { appendRequiredRules, appendNumberRules } from "./plugins/validationRules";
import axios from "axios";
import VueAxiosPlugin from "./plugins/vue-axios";

Vue.use(Vuetify);
Vue.use(VueAxiosPlugin, { axios: axios });

Vue.use(LoadingPlugin, {
  spinnerProps: {},
  overlayProps: {},
});

Vue.config.productionTip = false;

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
appendRequiredRules();
appendNumberRules();

// フィルターをグローバルに登録
import { formatNumber, formatDate } from "./filters/filters";
Vue.filter("formatNumber", formatNumber);
Vue.filter("formatDate", formatDate);

// 地域
const placeRule = (v: string): boolean | string => {
  const regex = /^[あ-ん]{3}$|^[一-鿐ヶ]{1,4}$|^$/; // 正規表現
  return regex.test(v) || "不正な値"; // エラーメッセージ
};

// クラスナンバー
const classNumberRule = (v: string): boolean | string => {
  const regex = /^[0-9][0-9ACFHKLMPXY][0-9ACFHKLMPXY]$|^[0-9][0-9ACFHKLMPXY]$|^[35]$/; // 正規表現
  return regex.test(v) || "不正な値"; // エラーメッセージ
};

// かな
const kanaRule = (v: string): boolean | string => {
  const regex = /^[あ-えか-さす-ふほ-をABEHKMTY]$|^$/; // 正規表現
  return regex.test(v) || "不正な値"; // エラーメッセージ
};

// 4桁ナンバー
const carNumberRule = (v: string): boolean | string => {
  const regex = /^\*\*\*[1-9]$|^\*\*[1-9][0-9]$|^\*[1-9][0-9][0-9]$|^[1-9][0-9][0-9][0-9]$|^$/; // 正規表現
  return regex.test(v) || "不正な値"; // エラーメッセージ
};

// 必須項目チェック（オプションとして用意）
const requiredRule = (v: string): boolean | string => {
  return !!v || "必須項目"; // 空値の場合エラー
};

Vue.prototype.$placeRule = placeRule;
Vue.prototype.$classNumberRule = classNumberRule;
Vue.prototype.$kanaRule = kanaRule;
Vue.prototype.$carNumberRule = carNumberRule;
Vue.prototype.$requiredRule = requiredRule;

// Vueインスタンスの生成
new Vue({
  vuetify: new Vuetify(),
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
