import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/home.vue";
import Servers from "../views/Servers.vue";
import OutputUnpaidRecords from "../views/OutputUnpaidRecords.vue";
import OutputUnpaidRecordsStaying from "../views/OutputUnpaidRecordsStaying.vue";
import OutputCheckoutRecords from "../views/OutputCheckoutRecords.vue";
import OutputUnpaidForm from "../views/OutputUnpaidForm.vue";
import ResultUnpaidRecordsStaying from "../views/ResultUnpaidRecordsStaying.vue";

import UnfairVehicles from "../views/UnfairVehicles.vue";
import ParkingAvailabilityReports from "../views/ParkingAvailabilityReports.vue";

import Login from "../views/login.vue";
import SignUp from "../views/user/SignUp.vue";
import UserList from "../views/user/UserList.vue";
import UserUpdate from "../views/user/UserUpdate.vue";
import InvalidLink from "../views/error/InvalidLink.vue";
import NotFound from "../views/error/404.vue";
import BacklogAggregate from "../views/BacklogAggregate.vue";

import NotNumber from "../views/NotNumber.vue";
import QueryTools from "../views/QueryTools.vue";
import SqlSelect from "../views/Examination/SqlSelect/index.vue";
import UpdateCheckSQL from "../views/UpdateCheckSQL.vue";
import UpdateCheckTheDayBefore from "../views/UpdateCheckTheDayBefore.vue";
import UpdateCheckOnTheDay from "../views/UpdateCheckOnTheDay.vue";
import OutputUnauthorizedUseVehicles from "../views/OutputUnauthorizedUseVehicles.vue";
import AlertVehicleBulkRegistration from "../views/AlertVehicleBulkRegistration.vue";

// 情報検索
import OutputUnpaidResearch from "../views/OutputUnpaidResearch.vue";
import OutputBlackListResearch from "../views/OutputBlackListResearch.vue";
import VehiclesInformation from "../views/VehiclesInformation.vue";
import VehiclesInformationShow from "../views/VehiclesInformationShow.vue";
import MissRecognized from "../views/Search/MissRecognized/index.vue";
import LongTermParkingVehicle from "../views/Search/LongTermParkingVehicle/index.vue";

// 公開API
import SelectInOutInformation from "../views/openapi/InOutMgr/SelectInOutInformation.vue";
import AllBlackLists from "../views/openapi/BlackLists/AllBlackLists.vue";
import AllBlackListsDelete from "../views/openapi/BlackLists/AllBlackListsDelete.vue";
import SelectBlackLists from "../views/openapi/BlackLists/SelectBlackLists.vue";
import SelectBlackListsDelete from "../views/openapi/BlackLists/SelectBlackListsDelete.vue";

// データサイエンス
import SalesForecast from "../views/datascience/SalesForecast/index.vue";
import Groups from "../views/datascience/Groups/index.vue";

// お知らせ
import Notifications from "../views/Notifications/Send/index.vue";
import LabelsNotifications from "../views/Notifications/ByParking/index.vue";

// WebUI children
import WebUILongTermParkingVehicle from "../views/WebUI/LongTermParkingVehicle/index.vue";
import WebUIErrors from "../views/WebUI/Errors/index.vue";
import WebUIMissRecognizedVehicles from "../views/WebUI/MissRecognizedVehicles/index.vue";
import WebUIVehicleInformations from "../views/WebUI/VehicleInformations/index.vue";
import WebUIAllVehicles from "../views/WebUI/AllVehicles/index.vue";
import WebUICheckBases from "../views/WebUI/CheckBases/index.vue";
import WebUIUnpaidResearchs from "../views/WebUI/UnpaidResearchs/index.vue";
import WebUIParkings from "../views/WebUI/Parkings/index.vue";
import WebUICalendars from "../views/WebUI/Calendars/index.vue";
import WebUICalendarHolidays from "../views/WebUI/CalendarHolidays/index.vue";
import WebUICashVouchers from "../views/WebUI/CashVouchers/index.vue";
import WebUIAccounts from "../views/WebUI/Accounts/index.vue";
import WebUIAlerts from "../views/WebUI/Alerts/index.vue";
import WebUIAlertsMaintenanceArrangements from "../views/WebUI/Alerts/MaintenanceArrangements/index.vue";
import WebUIAlertsEmergencyArrangements from "../views/WebUI/Alerts/EmergencyArrangements/index.vue";
import WebUIAlertsStore from "../views/WebUI/Alerts/Store/index.vue";
import WebUIAlertsMaintenanceSupport from "../views/WebUI/Alerts/MaintenanceSupport/index.vue";
import WebUIAlertsSalesManagement from "../views/WebUI/Alerts/SalesManagement/index.vue";
import WebUIAlertsCallCenter from "../views/WebUI/Alerts/CallCenter/index.vue";
import WebUIBlackLists from "../views/WebUI/BlackLists/index.vue";
import WebUICommonsVehicleNotes from "../views/WebUI/CommonsVehicleNotes/index.vue";
import WebUISharedUnpaidLists from "../views/WebUI/SharedUnpaidLists/index.vue";
import WebUITimeRangeDeletion from "../views/WebUI/TimeRangeDeletion/index.vue";
import WebUIAutoExitExcludeVehicles from "../views/WebUI/AutoExitExcludeVehicles/index.vue";

Vue.use(VueRouter);

// 【アクセス制限】role_id:4のユーザーが指定のパスを手入力、またはclick等でアクセスを要求した場合は、問答無用でログインページに飛ばす(beforeEnterにて使用)
function roleId04Guard(to, from, next) {
  if (JSON.parse(localStorage.user).role_id === 4) {
    next({ path: "/", name: "login", component: Login }); // role_id:4はここに飛ばす
  } else {
    next(); // role_id:4以外ではそのままアクセスを許可
  }
}

const routes: Array<RouteConfig> = [
  { path: "/", name: "login", component: Login },
  { path: "/servers", name: "servers", component: Servers, beforeEnter: roleId04Guard },
  { path: "/alert-vehicle-bulk-registration", name: "alert-vehicle-bulk-registration", component: AlertVehicleBulkRegistration, beforeEnter: roleId04Guard },
  { path: "/unpaid-records", name: "output-unpaid-records", component: OutputUnpaidRecords, beforeEnter: roleId04Guard },
  { path: "/unpaid-records-staying", name: "output-unpaid-records-staying", component: OutputUnpaidRecordsStaying, beforeEnter: roleId04Guard },
  { path: '/output-unpaid-records-staying-result/:id/:name', name: 'output-unpaid-records-staying-result', component: ResultUnpaidRecordsStaying, beforeEnter: roleId04Guard },
  { path: "/checkout-records", name: "output-checkout-records", component: OutputCheckoutRecords, beforeEnter: roleId04Guard },
  { path: "/unpaid-form", name: "unpaid-form", component: OutputUnpaidForm, beforeEnter: roleId04Guard },
  { path: "/unfair-vehicles", name: "unfair-vehicles", component: UnfairVehicles, beforeEnter: roleId04Guard },
  { path: "/parking-availability-reports", name: "parking-availability-reports", component: ParkingAvailabilityReports, beforeEnter: roleId04Guard },
  { path: "/sign-up", name: "sign-up", component: SignUp, beforeEnter: roleId04Guard },
  { path: "/user-update/:login_id", name: "user-update", component: UserUpdate },
  { path: "/user-list", name: "user-list", component: UserList, beforeEnter: roleId04Guard },
  { path: "/invalid-link", name: "invalid-link", component: InvalidLink, beforeEnter: roleId04Guard },
  { path: "/backlog-aggregate", name: "backlog-aggregate", component: BacklogAggregate, beforeEnter: roleId04Guard },
  { path: '/not-number', name: 'not-number', component: NotNumber },
  { path: "/query-tools", name: "query-tools", component: QueryTools, beforeEnter: roleId04Guard },
  { path: "/update-check-sql", name: "update-check-sql", component: UpdateCheckSQL, beforeEnter: roleId04Guard },
  { path: "/update-check-the-day-before", name: "update-check-the-day-before", component: UpdateCheckTheDayBefore, beforeEnter: roleId04Guard },
  { path: "/update-check-on-the-day", name: "update-check-on-the-day", component: UpdateCheckOnTheDay, beforeEnter: roleId04Guard },
  { path: "/sql_select", name: "sql-select", component: SqlSelect },
  { path: '/output-unauthorized-use-vehicles', name: 'output-unauthorized-use-vehicles', component: OutputUnauthorizedUseVehicles, beforeEnter: roleId04Guard },

  // 情報検索
  { path: "/unpaid-research", name: "unpaid-research", component: OutputUnpaidResearch, beforeEnter: roleId04Guard },
  { path: "/black-list-research", name: "black-list-research", component: OutputBlackListResearch, beforeEnter: roleId04Guard },
  { path: "/vehicles-information", name: "vehicles-information", component: VehiclesInformation},
  { path: '/vehicles-information-show', name: 'vehicles-information-show', component: VehiclesInformationShow},
  { path: '/miss-recognized', name: 'miss-recognized', component: MissRecognized},
  { path: '/long-term-parking-vehicle', name: 'long-term-parking-vehicle', component: LongTermParkingVehicle},

  // 公開API
  { path: '/select-in-out-information', name: 'select-in-out-information', component: SelectInOutInformation, beforeEnter: roleId04Guard },
  { path: '/all-black-lists', name: 'all-black-lists', component: AllBlackLists, beforeEnter: roleId04Guard },
  { path: '/all-black-lists-delete', name: 'all-black-lists-delete', component: AllBlackListsDelete, beforeEnter: roleId04Guard },
  { path: '/select-black-lists', name: 'select-black-lists', component: SelectBlackLists, beforeEnter: roleId04Guard },
  { path: '/select-black-lists-delete', name: 'select-black-lists-delete', component: SelectBlackListsDelete, beforeEnter: roleId04Guard },

  // データサイエンス
  // 売り上げ予測
  { path: '/sales-forecast', name: 'sales-forecast', component: SalesForecast, beforeEnter: roleId04Guard },
  { path: '/groups', name: 'groups', component: Groups, beforeEnter: roleId04Guard },

  // お知らせ
  { path: '/notifications', name: 'notifications', component: Notifications },
  { path: '/labels-notifications', name: 'labels-notifications', component: LabelsNotifications, beforeEnter: roleId04Guard },

  // home(WebUIメニュー)
  { 
    path: '/home',
    name: 'home',
    component: Home,
    children: [
      {
        path: '/WebUI/long-term-parking-vehicle',
        component: WebUILongTermParkingVehicle
      },
      {
        path: '/WebUI/errors',
        component: WebUIErrors
      },
      {
        path: '/WebUI/miss-recognized-vehicles',
        component: WebUIMissRecognizedVehicles
      },
      {
        path: '/WebUI/vehicle-informations',
        component: WebUIVehicleInformations
      },
      {
        path: '/WebUI/all-vehicles',
        component: WebUIAllVehicles
      },
      {
        path: '/WebUI/check-bases',
        component: WebUICheckBases
      },
      {
        path: '/WebUI/unpaid-researchs',
        component: WebUIUnpaidResearchs
      },
      {
        path: '/WebUI/parkings',
        component: WebUIParkings
      },
      {
        path: '/WebUI/calendars',
        component: WebUICalendars
      },
      {
        path: '/WebUI/calendar-holidays',
        component: WebUICalendarHolidays
      },
      {
        path: '/WebUI/cash-vouchers',
        component: WebUICashVouchers
      },
      {
        path: '/WebUI/accounts',
        component: WebUIAccounts
      },
      {
        path: '/WebUI/alerts',
        component: WebUIAlerts,
        children: [
          {
            path: '/WebUI/alerts/maintenance-arrangements',
            component: WebUIAlertsMaintenanceArrangements
          },
          {
            path: '/WebUI/alerts/emergency-arrangements',
            component: WebUIAlertsEmergencyArrangements
          },
          {
            path: '/WebUI/alerts/stores',
            component: WebUIAlertsStore
          },
          {
            path: '/WebUI/alerts/maintenance-support',
            component: WebUIAlertsMaintenanceSupport
          },
          {
            path: '/WebUI/alerts/sales-management',
            component: WebUIAlertsSalesManagement
          },
          {
            path: '/WebUI/alerts/call-center',
            component: WebUIAlertsCallCenter
          },
        ]
      },
      {
        path: '/WebUI/black-lists',
        component: WebUIBlackLists
      },
      {
        path: '/WebUI/commons-vehicle-notes',
        component: WebUICommonsVehicleNotes
      },
      {
        path: '/WebUI/shared-unpaid-lists',
        component: WebUISharedUnpaidLists
      },
      {
        path: '/WebUI/time-range-deletion',
        component: WebUITimeRangeDeletion
      },
      {
        path: '/WebUI/auto-exit-exclude-vehicles',
        component: WebUIAutoExitExcludeVehicles
      },
    ]
  },

  // その他（一番下に！）
  { path: '/*', name: 'NotFound', component: NotFound, beforeEnter: roleId04Guard }
];

const router = new VueRouter({
  namespaced: true,
  mode: "history",
  base: process.env.VUE_APP_FRONT_BASE_PATH,
  routes
});

// ログイン中か否かの判断（よろしくない）
router.beforeEach((to, from, next) => {
  // ログインページ
  if (to.name === "login") {
    next();
    return;
  }
  // ユーザー登録画面
  if (to.name === "user-update") {
    next();
    return;
  }
  // ログインページ以外のページ
  if (localStorage.user) {
        next();
      } else {
        next('/');
  }
});

export default router;
