




























































































import Vue from "vue";
import axios from "axios";
import store from "/front/src/store/WebUI.ts";
interface Data {
  place:string;
  classNumber:string;
  kana:string;
  carNumber:string;
  kindId:number;
  address:string;
  addresses:any[];
  kindItem:any[];
  alertTitle:string;
  alertText:string;
  note:string;
  valid: boolean;
}
export default Vue.extend({
  data(): Data {
    return {
      place:"",
      classNumber:"",
      kana:"",
      carNumber:"",
      kindId:0,
      address:"",
      addresses:[],
      kindItem:[
        {text:"未払い", value:0},
        {text:"店舗指定", value:1},
        {text:"警察指定", value:2},
        {text:"その他", value:3}
      ],
      alertTitle:"",
      alertText:"",
      note:"",
      valid: true,
    }
  },
  props:{
    selectParking:{},
    createLoading:{}
  },
  methods: {
    // メールアドレスを登録する
    pushAddress(value: string) {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!emailRegex.test(value)) {
        console.log(`${value} は無効なメールアドレスです。`);
        return; // 正しくない場合は処理を終了
      }
      if (this.addresses == null) {
        this.addresses.push(value);
        console.log(`${value} が登録されました。`);
      } else {
        if (this.addresses.includes(value)) {
          console.log(`${value} はすでに登録済み。`);
        } else {
          this.addresses.push(value);
          console.log(`${value} が登録されました。`);
        }
      }
      this.address = "";
    },
    // メールアドレスを削除する
    removeAddress(value:string){
      this.addresses = this.addresses.filter(item => item !== value);
    },
    // 車両を登録する
    async create(
      parkingId:number,
      place:string,
      classNumber:string,
      kana:string,
      carNumber:string,
      kindId:number,
      alertTitle:string,
      alertText:string,
      note:string,
      addresses:any
    ): Promise<void> {
      if (!this.$refs.form.validate()) return
      if (!this.selectServer) { alert("物件を選択してください"); this.result = []; return; }
      this.$emit('loading', true);
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/web_ui/black_list_mgr`;
      const formData = {
        SERVER_ID: this.selectServer,
        PARKING_ID: parkingId,
        PLACE: place,
        CLASS_NUMBER: classNumber,
        KANA: kana,
        CAR_NUMBER: carNumber,
        KIND_ID: kindId,
        ALERT_TITLE: alertTitle,
        ALERT_TEXT: alertText,
        NOTE: note,
        MAIL_ADDRESS: addresses
      }
      return await axios
      .post(url, formData)
      .then((res:any) => {
        if (res.data.error){ alert(res.data.message); return;}; 
        this.place = "";
        this.classNumber = "";
        this.kana = "";
        this.carNumber = "";
        this.kindId = 0;
        this.address = "";
        this.addresses = [];
        this.alertTitle = "";
        this.alertText = "";
        this.note = "";
        this.$emit('reload');
        this.$emit('close');
        alert("成功しました");
      })
      .catch((error: any) => {
        alert(error);
      })
      .finally(() => {
        this.$emit('loading', false);
      });
    },
  },
  computed: {
    selectServer() {
      return store.state.WebUI.server;
    }
  }
});
