









import Vue from "vue";
import * as XLSX from 'xlsx'; // xlsx ライブラリをインポート
import { formatDate } from '@/filters/filters'; // 日付を変換するフィルターをインポート

export default Vue.extend({
  props: {
    selectObj: {
      type: Array,
      required: true,
    },
  },
  methods: {
    downloadExcel() {
      if (this.selectObj.length === 0) return;

      // データの整形処理: 日付を変換
      const formatData = this.selectObj.map(obj => {
        const formattedObj = { ...obj };

        // オブジェクトの各フィールドをチェックし、ISO 8601形式の日付を変換
        Object.keys(formattedObj).forEach(key => {
          const value = formattedObj[key];
          if (value && typeof value === 'string' && value.includes('T') && value.includes(':')) {
            formattedObj[key] = formatDate(value); // formatDateを使用して日付を変換
          }
        });

        return formattedObj;
      });

      // ヘッダーとデータを取得
      const headers = Object.keys(formatData[0]);
      const rows = formatData.map(obj => Object.values(obj));

      // データをワークシート形式に変換
      const ws = XLSX.utils.aoa_to_sheet([headers, ...rows]);

      // ワークブックを作成
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

      // Excelファイルとしてダウンロード
      XLSX.writeFile(wb, 'data.xlsx');
    },
  },
});
