










































































import Vue from "vue";
import axios from "axios";

interface Params {
  headers: any[] | null;
  result: any[]| null;
  copyRecord: any[];
  valid: boolean;
  startDate: string;
  endDate: string;
}

export default Vue.extend({
  data(): Params {
    return {
      headers: null,
      result: null,
      copyRecord: [],
      valid: true,
      startDate: "",
      endDate: "",
    }
  },
  computed: {
    // テーブルのレコードを選択するためにレコードにインデックス（一意の値）を追加（入出庫）
    indexedItems () {
      return this.result.map((item, index) => ({
        index: index,
        ...item
      }))
    },
  },
  methods: {
    // 終点に始点＋1を代入する
    andOne(num:number) {
      // 現在の日付を取得
      const end = new Date();
      // 昨日の日付を取得するために、今日の日付から1日引く
      const start = new Date(end);
      start.setDate(start.getDate() - num);
      // 日付を 'yyyy-mm-dd' フォーマットに変換する関数
      function formatDate(date:Date) {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
      }
      // フォーマットされた今日と昨日の日付
      if (num != 9999) {
        this.startDate = formatDate(start);
      } else {
        // 全期間
        this.startDate = "2000-01-01";
      }
      this.endDate = formatDate(end);
    },
    // SQL実行
    async send(startDate:string, endDate:string): Promise<void>{
      if (!this.$refs.form.validate()) return
      this.result = [];
      this.$vloading.show();
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/sql_select/sql16`;
      const formData = { params:
        { 
          startDate: startDate,
          endDate: endDate
        }
      };
      return await axios
      .post(url, formData.params)
      .then(res => {
        if (res.data.length == 0) {
          alert("検索結果がありませんでした");
        } else {
          this.copyRecord = [];
          this.result = res.data;
          const headerAry = [];
          for (const element of Object.keys(res.data[0])) {
            headerAry.push({text: element, value: element })
          };
          this.headers = headerAry;
        }
      })
      .catch(error => {
        console.log(error);
        alert("検索に失敗しました");
      })
      .finally(() => {
        this.$vloading.hide();
      });
    },
    copyToClipBoard(): void{
      if(this.copyRecord.length != 0) {
        const text = document.getElementById('spl16copy');
        navigator.clipboard.writeText(text.innerText).then(e => {
          alert('コピーしました');
        });
      } else {
        alert('コピー対象を選んでください');
      }
    },
  },
})
