











































































































import Vue from "vue";
import axios from "axios";
import store from "/front/src/store/WebUI.ts";
import VehicleInformations from "@/components/WebUI/VehicleInformations/index.vue";
import ObjectToCsv from "@/components/OutPut/ObjectToCsv.vue"
import ObjectToBacklog from "@/components/OutPut/ObjectToBacklog.vue"
import ObjectToExcel from "@/components/OutPut/ObjectToExcel.vue"
interface Data {
  result:any[];
  headers:any[];
  successCarNumber:string;
  show: any[];
  dialog: boolean;
  selected:any[];
  place:string;
  classNumber:string;
  kana:string;
  carNumber:string;
  createDialog:boolean;
  createLoading:boolean;
  valid:boolean;
}
export default Vue.extend({
  data(): Data {
    return {
      result:[],
      headers: [
        { text: 'ID', value: 'id' },
        { text: '地域', value: 'place' },
        { text: 'クラス', value: 'class_number' },
        { text: 'かな', value: 'kana' },
        { text: 'ナンバー', value: 'car_number' },
        { text: '', value: 'history' },
        { text: '削除', value: 'destroy' },
      ],
      successCarNumber:"",
      show: [],
      dialog: false,
      selected:[],
      place:"",
      classNumber:"",
      kana:"",
      carNumber:"",
      createDialog:false,
      createLoading:false,
      valid:false
    }
  },
  components:{
    VehicleInformations,
    ObjectToCsv,
    ObjectToBacklog,
    ObjectToExcel
  },
  watch: {
    selectServer(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.getResult();
      }
    }
  },
  mounted() {
    this.getResult()
  },
  methods: {
    async destroy(
      id:number,
      place:string,
      classNumber:string,
      kana:string,
      carNumber:string,
    ): Promise<void> {
      if (!window.confirm('削除しますか？')) return
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/web_ui/mainte_auto_exiter_exclude/${id}`;
      return await axios
      .delete(url, { params: {
        SERVER_ID: this.selectServer,
        place: place,
        classNumber: classNumber,
        kana: kana,
        carNumber: carNumber,
      } })
      .then(() => {
        alert("削除しました");
      })
      .catch((error: any) => {
        console.log(error)
        alert("削除に失敗しました");
      })
      .finally(() => {
        this.getResult();
      });
    },
    async create(
      place:string,
      classNumber:string,
      kana:string,
      carNumber:string,
    ): Promise<void> {
      if (!this.$refs.form.validate()) return
      if (!this.selectServer) { alert("物件を選択してください"); this.result = []; return; }
      this.createLoading = true;
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/web_ui/mainte_auto_exiter_exclude`;
      const formData = {
        SERVER_ID: this.selectServer,
        place: place,
        classNumber: classNumber,
        kana: kana,
        carNumber: carNumber,
      }
      return await axios
      .post(url, formData)
      .then((res:any) => {
        if (res.data.error){ alert(res.data.message); return;}; 
        this.place = "";
        this.classNumber = "";
        this.kana = "";
        this.carNumber = "";
        alert("成功しました");
        this.getResult();
      })
      .catch((error: any) => {
        alert(error);
      })
      .finally(() => {
        this.createDialog = false;
        this.createLoading = false;
      });
    },
    async history(place:string, classNumber:string, kana:string, carNumber:string): Promise<void> {
      this.$vloading.show();
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/vehicles_information_show`;
      const formData = { params: {
                                  server:      this.selectServer,
                                  place:       place,
                                  classNumber: classNumber,
                                  kana:        kana,
                                  carNumber:   carNumber
                        } };
      return await axios
      .get(url, formData)
      .then(res => {
        this.successCarNumber = place + ' ' + classNumber + ' ' + kana + ' ' + carNumber;
        this.show = res.data;
        this.dialog = true;
      })
      .catch(error => {
        alert(error);
      })
      .finally(() => {
        this.$vloading.hide()
      });
    },
    async getResult(): Promise<void> {
      if (!this.selectServer) { alert("物件を選択してください"); this.result = []; return; }
      this.$vloading.show();
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/web_ui/mainte_auto_exiter_exclude`;
      return await axios
      .get(url, { params: { SERVER_ID: this.selectServer } } )
      .then((res:any) => {
        this.result = res.data;
      })
      .catch((error: any) => {
        console.log(error)
        alert("物件への接続に失敗しました");
        this.result = [];
      })
      .finally(() => {
        this.$vloading.hide();
      });
    },
  },
  computed: {
    selectServer() {
      return store.state.WebUI.server;
    }
  }
});
