




















































































import Vue from "vue";
import axios from "axios";
interface Params {
  result: any;
  dialog: boolean;
}
export default Vue.extend({
  data(): Params {
    return {
      result: [],
      dialog: false,
    }
  },
  methods: {
    async getParkingMgr(): Promise<void> {
      if (!this.selectServer) { alert("物件を選択してください"); this.result = []; return; }
      this.$vloading.show();
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/web_ui/parking_mgr`;
      return await axios
      .get(url, { params: { SERVER_ID: this.selectServer } } )
      .then((res:any) => {
        this.result = res.data;
        this.dialog = true;
      })
      .catch((error: any) => {
        console.log(error)
        alert("物件への接続に失敗しました");
        this.result = [];
      })
      .finally(() => {
        this.$vloading.hide();
      });
    },
  },
  props:{
    selectServer:{}
  }
});
