









































































import Vue from "vue";
import axios from "axios";
import store from "/front/src/store/WebUI.ts";
import VehicleInformations from "@/components/WebUI/VehicleInformations/index.vue";

interface Params {
  place: string;
  classNumber: string;
  kana: string;
  carNumber: string;
  result: any[]| null;
  valid: boolean;
  successCarNumber: string;
}

export default Vue.extend({
  data(): Params {
    return {
      place: "",
      classNumber: "",
      kana: "",
      carNumber: "",
      successCarNumber: "",
      result: null,
      valid: true,
    }
  },
  components: {
    VehicleInformations
  },
  computed: {
    selectServer() {
      return store.state.WebUI.server;
    }
  },
  watch: {
    place(input:string) {
      this.fullNumberPush(input);
    },
    classNumber(input:string) {
      this.fullNumberPush(input);
    },
    kana(input:string) {
      this.fullNumberPush(input);
    },
    carNumber(input:string) {
      this.fullNumberPush(input);
    },
  },
  methods: {
    fullNumberPush(input:string) {
      // 半角スペースがなく正しいナンバーの場合
      const pattern = /^([あ-ん]{3}|[一-鿐ヶ]{1,4})([1-9][0-9][0-9ACFHKLMPXY]|[1-9][0-9])([あ-えか-さす-ふほ-を])(\*\*\*[1-9]|\*\*[1-9][0-9]|\*[1-9][0-9][0-9]|[1-9][0-9][0-9][0-9])$/;
      const match = input.match(pattern);
      if (match) {
        this.place = match[1];
        this.classNumber = match[2];
        this.kana = match[3];
        this.carNumber =  match[4];
        return
      // 半角スペースがある場合
      } else {
        const regex = {
          place: /^[あ-ん]{3}$|^[一-鿐ヶ]{1,4}$/,
          class: /^[1-9][0-9][0-9ACFHKLMPXY]$|^[1-9][0-9]$/,
          kana: /^[あ-えか-さす-ふほ-を]$/,
          carNumber: /^\*\*\*[1-9]$|^\*\*[1-9][0-9]$|^\*[1-9][0-9][0-9]$|^[1-9][0-9][0-9][0-9]$/
        };
        // 半角スペースがある場合はそれで分割、なければ全体が1つの文字列
        const parts = input.split(' ').filter(Boolean);
        // 配列の要素が4つでなければ失敗
        if (parts.length !== 4) return ; // 失敗
        // それぞれのパーツが正しい正規表現に一致するか確認
        if (!parts[0].match(regex.place) ||
            !parts[1].match(regex.class) ||
            !parts[2].match(regex.kana) ||
            !parts[3].match(regex.carNumber)) {
          return // 失敗
        }
        // すべてのチェックをパスしたらオブジェクトを返す
        this.place = parts[0];
        this.classNumber = parts[1];
        this.kana = parts[2];
        this.carNumber = parts[3];
        return
      }
    },
    async send(): Promise<void> {
      if (!this.selectServer) { alert("物件を選択してください"); return; }
      this.$vloading.show();
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/vehicles_information_show`;
      const formData = { params: {
                                  server:      this.selectServer,
                                  place:       this.place,
                                  classNumber: this.classNumber,
                                  kana:        this.kana,
                                  carNumber:   this.carNumber
                        } };
      return await axios
      .get(url, formData)
      .then(res => {
        this.successCarNumber = this.place + ' ' + this.classNumber + ' ' + this.kana + ' ' + this.carNumber;
        this.result = res.data;
        this.$vloading.hide();
      })
      .catch(error => {
        console.log(error);
        alert("検索に失敗しました");
        this.$vloading.hide();
      });
    },
  },
});
