

























import Vue from "vue";
import store from "/front/src/store/WebUI.ts";
interface Data {
  menu:any[]
}
export default Vue.extend({
  data(): Data {
    return {
      menu:[
        {title:"ローカルサーバー設定", text:"localの「tbl_account_mgr」を参照して更新します。"},
        {title:"カメラ設定", text:"localの「tbl_recognizer_camera_settings」を参照して更新します。ENTRANT_IDの選択には「tbl_exit_entrance_info」を使用します。"},
        {title:"アラートテスト", text:"localにソケット通信を行い、local内の「tbl_alert_mail_address」に登録されてあるメールアドレス全てにテストアラートを送信します。送信処理はlocalによって管理されます。"},
        {title:"アラート一覧", text:"localの「tbl_error_log」を参照します。デフォルトの取得レコードは現在時間から１か月までまでです。"}
      ]
    }
  },
  computed: {
    selectServer() {
      return store.state.WebUI.server;
    }
  }
});
