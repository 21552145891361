








































































































































import Vue from "vue";
import axios from "axios";
import store from "/front/src/store/WebUI.ts";
import VehicleInformations from "@/components/WebUI/VehicleInformations/index.vue";
import ObjectToCsv from "@/components/OutPut/ObjectToCsv.vue"
import ObjectToBacklog from "@/components/OutPut/ObjectToBacklog.vue"
import ObjectToExcel from "@/components/OutPut/ObjectToExcel.vue"
interface Data {
  result:any[];
  headers:any[];
  dialog: boolean;
  show: any[];
  successCarNumber:string;
  selected:any[];
  unpaidCount:number;
  finishdFlg:boolean;
  valid:boolean;
  interval:number;
  total:number;
}
export default Vue.extend({
  data(): Data {
    return {
      result:[],
      headers:[
        { text: 'ナンバー', value: "NUMBER" },
        { text: 'アラート通知登録', value: "BLACK_ID" },
        { text: 'アラートタイトル', value: "ALERT_TITLE" },
        { text: '未払い累積金額', value: "TOTAL_PAY_ARREARS" },
        { text: '未払い累積回数', value: "UNPAYD_COUNT" },
      ],
      successCarNumber:"",
      show: [],
      dialog: false,
      selected:[],
      unpaidCount:10,
      finishdFlg: false,
      valid:false,
      interval:0,
      total:40000
    }
  },
  components:{
    VehicleInformations,
    ObjectToCsv,
    ObjectToBacklog,
    ObjectToExcel
  },
  watch: {
    selectServer(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.getResult();
      }
    }
  },
  mounted() {
    this.getResult()
  },
  methods: {
    validateNumber(value:string) {
      if (value === null || value === undefined || value === '') {
        return ''; // 空の場合はエラーメッセージを返す
      }
      return /^[0-9]+$/.test(value) || '0以上の半角数字を入力してください';
    },
    async history(place:string, classNumber:string, kana:string, carNumber:string): Promise<void> {
      this.$vloading.show();
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/vehicles_information_show`;
      const formData = { params: {
                                  server:      this.selectServer,
                                  place:       place,
                                  classNumber: classNumber,
                                  kana:        kana,
                                  carNumber:   carNumber
                        } };
      return await axios
      .get(url, formData)
      .then(res => {
        this.successCarNumber = place + ' ' + classNumber + ' ' + kana + ' ' + carNumber;
        this.show = res.data;
        this.dialog = true;
      })
      .catch(error => {
        alert(error);
      })
      .finally(() => {
        this.$vloading.hide()
      });
    },
    async getResult(): Promise<void> {
      if (!this.selectServer) { alert("物件を選択してください"); this.result = []; return; }
      if (!this.$refs.form.validate()) return;
      this.$vloading.show();
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/web_ui/shared_unpaid_information`;
      return await axios
      .get(url, {params: {
        SERVER_ID: this.selectServer,
        UNPAYD_COUNT: this.unpaidCount,
        FINISHED_FLG: this.finishdFlg,
        INTERVAL: this.interval,
        TOTAL: this.total
      }} )
      .then((res:any) => {
        this.result = [];
        this.result = res.data;
      })
      .catch((error: any) => {
        alert(error);
      })
      .finally(() => {
        this.$vloading.hide();
      });
    },
  },
  computed: {
    selectServer() {
      return store.state.WebUI.server;
    }
  }
});
