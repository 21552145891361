// 数字を3桁で区切り
// 1000000 → 1,000,000 
export const formatNumber = (value: number): string => {
  if (!value) return '0';
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

// Railsで出力された日付データ（ISO 8601形式）を変換
// 2024-11-14T20:12:16.000+09:00 → 2024/11/14 20:12:16
export const formatDate = (value: string): string => {
  if (!value) return ''
  return new Date(value).toLocaleString('ja-JP', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  })
}
