






































































































import Vue from "vue";
import axios from "axios";

interface Params {
  place: string;
  classNumber: string;
  kana: string;
  carNumber: string;
  headers: any[] | null;
  result: any[]| null;
  copyRecord: any[];
  valid: boolean;
  fullNumber: string;
}

export default Vue.extend({
  data(): Params {
    return {
      place: "",
      classNumber: "",
      kana: "",
      carNumber: "",
      headers: null,
      result: null,
      copyRecord: [],
      valid: true,
      fullNumber: "",
    }
  },
  computed: {
    // テーブルのレコードを選択するためにレコードにインデックス（一意の値）を追加（入出庫）
    indexedItems () {
      return this.result.map((item, index) => ({
        index: index,
        ...item
      }))
    },
  },
  methods: {
    // SQL実行
    async send(place:string, classNumber:string, kana:string, carNumber:string): Promise<void>{
      if (!this.$refs.form.validate()) return
      this.result = [];
      this.$vloading.show();
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/sql_select/sql17`;
      const formData = { params:
        { 
          place: place,
          classNumber: classNumber,
          kana: kana,
          carNumber: carNumber,
        }
      };
      return await axios
      .post(url, formData.params)
      .then(res => {
        if (res.data.length == 0) {
          alert("検索結果がありませんでした");
        } else {
          this.copyRecord = [];
          this.result = res.data;
          const headerAry = [];
          for (const element of Object.keys(res.data[0])) {
            headerAry.push({text: element, value: element })
          };
          this.headers = headerAry;
        }
      })
      .catch(error => {
        console.log(error);
        alert("検索に失敗しました");
      })
      .finally(() => {
        this.$vloading.hide();
      });
    },
    copyToClipBoard(): void{
      if(this.copyRecord.length != 0) {
        const text = document.getElementById('spl17copy');
        navigator.clipboard.writeText(text.innerText).then(e => {
          alert('コピーしました');
        });
      } else {
        alert('コピー対象を選んでください');
      }
    },
    // フルナンバー登録
    assignNumberPlate(input:string) {
      // 半角スペースがなく正しいナンバーの場合
      const pattern = /^([あ-ん]{3}|[一-鿐ヶ]{1,4})([1-9][0-9][0-9ACFHKLMPXY]|[1-9][0-9])([あ-えか-さす-ふほ-を])(\*\*\*[1-9]|\*\*[1-9][0-9]|\*[1-9][0-9][0-9]|[1-9][0-9][0-9][0-9])$/;
      const match = input.match(pattern);
      if (match) {
        this.place = match[1];
        this.classNumber = match[2];
        this.kana = match[3];
        this.carNumber =  match[4];
        return
      // 半角スペースがある場合
      } else {
        const regex = {
          place: /^[あ-ん]{3}$|^[一-鿐ヶ]{1,4}$/,
          class: /^[1-9][0-9][0-9ACFHKLMPXY]$|^[1-9][0-9]$/,
          kana: /^[あ-えか-さす-ふほ-を]$/,
          carNumber: /^\*\*\*[1-9]$|^\*\*[1-9][0-9]$|^\*[1-9][0-9][0-9]$|^[1-9][0-9][0-9][0-9]$/
        };
        // 半角スペースがある場合はそれで分割、なければ全体が1つの文字列
        const parts = input.split(' ').filter(Boolean);
        // 配列の要素が4つでなければ失敗
        if (parts.length !== 4) return ; // 失敗
        // それぞれのパーツが正しい正規表現に一致するか確認
        if (!parts[0].match(regex.place) ||
            !parts[1].match(regex.class) ||
            !parts[2].match(regex.kana) ||
            !parts[3].match(regex.carNumber)) {
          return // 失敗
        }
        // すべてのチェックをパスしたらオブジェクトを返す
        this.place = parts[0];
        this.classNumber = parts[1];
        this.kana = parts[2];
        this.carNumber = parts[3];
        return
      }
    },
  },
})
