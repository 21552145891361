






































































import Vue from "vue";
import axios from "axios";

interface Params {
  checkOutDate:string;
  payTotal:string;
  headers: any[] | null;
  result: any[]| null;
  copyRecord: any[];
  valid: boolean;
}

export default Vue.extend({

  data(): Params {
    return {
      headers: null,
      result: null,
      copyRecord: [],
      valid: true,
      checkOutDate:"",
      payTotal:"",
    }
  },

  props: {
    selectParking: {}
  },

  computed: {
    // テーブルのレコードを選択するためにレコードにインデックス（一意の値）を追加（入出庫）
    indexedItems () {
      return this.result.map((item, index) => ({
        index: index,
        ...item
      }))
    }
  },

  methods: {

    // SQL実行
    async send(selectParking:number, checkOutDate:string, payTotal:string): Promise<void>{
      if (!this.$refs.form.validate()) return
      this.result = [];
      this.$vloading.show();
      const url = `${process.env.VUE_APP_API_URL_BASE}/api/sql_select/sql12`;
      const formData = { params:
        { 
          selectParking: selectParking,
          checkOutDate: checkOutDate,
          payTotal: payTotal
        }
      };
      return await axios
      .post(url, formData.params)
      .then(res => {
        if (res.data.length == 0) return alert("検索結果がありませんでした");
        this.copyRecord = [];
        this.result = res.data;
        const headerAry = [];
        for (const element of Object.keys(res.data[0])) {
          headerAry.push({text: element, value: element })
        };
        this.headers = headerAry;
      })
      .catch(error => {
        console.log(error);
        alert("検索に失敗しました");
      })
      .finally(() => {
        this.$vloading.hide();
      });
    },
    // コピー
    copyToClipBoard(): void{
      if(this.copyRecord.length != 0) {
        const text = document.getElementById('spl12copy');
        navigator.clipboard.writeText(text.innerText).then(e => {
          alert('コピーしました');
        });
      } else {
        alert('コピー対象を選んでください');
      }
    },
  },
})
